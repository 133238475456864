import { _API_ } from "../../consts";
import _fetch from "../../_fetch";
import { TradeFeedEntry, UserTradeFeed } from "./trade-slice";

interface TradeFeedParams {
    min_credits: number;
    max_credits: number;
    user_name?: string;
}

export async function fetchTradeFeed(params: TradeFeedParams): Promise<TradeFeedEntry[]> {
    return await _fetch('api/trade/feed', 'POST', params);
}

export async function fetchTradeFeedByUser(user_name: string): Promise<UserTradeFeed> {
    return await _fetch('api/trade/feed/user', 'POST', { user_name });
}