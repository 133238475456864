import React from 'react';
import { Item, Rarity } from "../../features/items/item-slice";

interface Props {
    item: Item,
    onClick?: () => void,
}

const ItemContainer = ({ item, onClick }: Props) => {

    return (
        <div className={`item-element`} onClick={onClick}>
            <div className={`gradient ${getRarity(item)}`}></div>
            <div style={{ height: "100%", padding: "10px 15px", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div className='item-title'>
                    <span>{item.name}<br></br>
                        <small style={{ color: "grey" }}>{item.category}</small>
                    </span>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                    {item.img_url && <img src={item.img_url} alt="" />}
                </div>
            </div>
        </div>
    );
}

const getRarity = (item: Item) => {
    switch (item.rarity) {
        case Rarity.Common: return `common`;
        case Rarity.Uncommon: return `uncommon`;
        case Rarity.Limited: return `limited`;
        case Rarity.Rare: return `rare`;
        case Rarity.VeryRare: return `very-rare`;
        case Rarity.Import: return `import`;
        case Rarity.Exotic: return `exotic`;
        case Rarity.BlackMarket: return `black-market`;
    }
}

export default ItemContainer;