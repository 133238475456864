import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const getSlice = (state: RootState) => state.trade;

const getUserPlatforms = createSelector(
    [getSlice],
    slice => slice.userPlatforms
)

const getUser = (_: RootState, user: string) => user

export const makeGetUserPlatform = () => createSelector(
    [getUserPlatforms, getUser],
    (userPlatforms, user) => {

        return userPlatforms[user] ?? {
            isLoading: false,
            platforms: [],
        }
    }
)

export const isUserPlatformRequested = createSelector(
    [getUserPlatforms, getUser],
    (userPlatforms, user) => user in userPlatforms
);