import { TradeCredits, TradeType } from "../../features/analysis/analysis-slice";
import { ConcreteItem } from "../../features/items/item-slice";
import TradeOffer from "./TradeOffer";

interface TradeOfferGroupProps {
    item: ConcreteItem;
    trades: TradeCredits[];
    type: TradeType;
    top_profit: number;
}

const TAKE_TOP = 5;

const TradeOfferGroup = ({ type, item, trades, top_profit }: TradeOfferGroupProps) => {
    return <>
        {trades.slice(0, TAKE_TOP).map(({ trade, credits }, i) => (
            <TradeOffer
                key={`${i}/${trade.user_name}/${trade.timestamp}/${credits}`}
                type={type} item={item} trade={trade} credits={credits} isProfit={i < top_profit}
            />
        ))}
        <small>
            and {Math.max(0, trades.length - TAKE_TOP)} more...
        </small>
    </>
}

export default TradeOfferGroup;