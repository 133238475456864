import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";

export function useNotification() {
    useEffect(() => {
        if (Notification.permission !== "granted") {
            Notification.requestPermission()
        }
    }, []);

    return useCallback((text: string) => {
        if (Notification.permission === "granted") {
            new Notification(text);
        }
        else {
            toast(text);
        }
    }, []);
}