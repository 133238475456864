import * as React from "react";
import { useEffect } from "react";
import { Container, FormGroup } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAppState } from "../../features/app/app-selectors";
import Anchor from "../common/Anchor";
import { Toaster } from "react-hot-toast";
import Sidebar from "../UI/SideBar";
import LoginPopup from '../Login/LoginPopup';
import { appActions } from "../../features/app/app-logic";
import TokenExpiration from "./TokenExpiration";
import { AppStateEnum } from "../../features/app/app-slice";

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {

    const dispatch = useAppDispatch();
    const appState = useAppSelector(getAppState);

    useEffect(() => {
        dispatch(appActions.initApp())
    }, [dispatch]);

    const handleLogin = async (token: string) => {
        await dispatch(appActions.initApp(token));
    }

    switch (appState) {
        case AppStateEnum.Initial: {
            return <></> // TODO loading indication
        }
        case AppStateEnum.ServerDown: {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h1>Server is down, please get back later</h1>
                </div>
            )
        }
        case AppStateEnum.Unathorized: {
            return <LoginPopup onSubmit={handleLogin}/>
        }
        case AppStateEnum.Initialized: {
            return (
                <Container>
                    <Toaster position="bottom-left"/>
                    <FormGroup style={{ display: 'flex', alignItems: "flex-end", justifyContent: 'space-between'}}>
                        <Sidebar />
                        <TokenExpiration />
                    </FormGroup>
                    {children}
                    <Anchor />
                </Container>
            )
        }
        default: {
            const typeguard: never = appState;
            throw new Error(`Unknown application state: ${typeguard}`)
        }
    }
}

export default Layout;