import * as React from "react";

interface Props {
    color?: string;
    size?: number;
    styles?: React.CSSProperties;
}

const Circle = ({ color, size = 12, styles }: Props) => {
    return (
        <div
            className="circle"
            style={{
                background: color ?? 'green',
                width: size,
                height: size,
                ...styles
            }}
        />
    )
}

export default Circle;