import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Trade } from "../analysis/analysis-slice";
import { Paint } from "../items/item-slice";

export interface TradeFeedEntry {
    rlg_id: number;
    rli_id: number;
    paint: Paint;
    credits: number;
    trade: Trade;
}

export interface UserTradeFeed {
    user_name: string;
    platforms: Platform[];
    feed: TradeFeedEntry[];
}

export enum Platform {
    Epic = 0,
    Steam = 1,
    PS = 2,
    Xbox = 3,
    Nintendo = 4,
    Discord = 5,
}

interface UserPlatform {
    isLoading: boolean;
    platforms: Platform[];
}

interface TradeState {
    userPlatforms: Record<string, UserPlatform | undefined>;
}

const initialState: TradeState = {
    userPlatforms: {},
};

export const tradeSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        receiveUserPlatform: (state, action: PayloadAction<[string, boolean, Platform[]]>) => {
            const [user, isLoading, platforms] = action.payload;
            state.userPlatforms[user] = { isLoading, platforms }
        },
    },
});

export default tradeSlice.reducer;
export const { receiveUserPlatform } = tradeSlice.actions;