import Moment from "react-moment";
import { FormGroup, Col, Row, Spinner } from "reactstrap";
import { AnalysisStatus, TradeType } from "../../features/analysis/analysis-slice";
import ItemContainer from "../PollCertainItems/ItemContainer";
import TradeOfferGroup from "../PollCertainItems/TradeOfferGroup";
import Nice from "./Nice";
import PaintBadge from "./PaintBadge";

interface Props {
    status: AnalysisStatus;
}

const AnalysisListEntry = ({ status: { item, result, isLoading }}: Props) => {
    return (
        <div className="selected-item-container">
            <FormGroup row>
                <Col xs={4}>
                    {result && <>
                        Profit: {result.profit > 0 ? <Nice>{result?.profit}</Nice> : 0}
                        &nbsp;
                        [<Moment date={result.timestamp} durationFromNow/> ago]
                        &nbsp;
                        {isLoading && <Spinner size='sm'/>}
                    </>
                    }
                    &nbsp;
                    <Row xs={7}>
                        <Col xs={7}>
                            <ItemContainer item={item} />
                            <PaintBadge paint={item.paint}/>
                        </Col>
                    </Row>
                </Col>
                {result ?
                    <>
                        <Col xs={4}>
                            <TradeOfferGroup type={TradeType.Has} item={item} trades={result.who_has} top_profit={result.top_profit_who_has}/>
                        </Col>
                        <Col xs={4}>
                            <TradeOfferGroup type={TradeType.Wants} item={item} trades={result.who_wants} top_profit={result.top_profit_who_wants}/>
                        </Col>
                    </> : <>
                        <Col xs={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner />
                        </Col>
                    </>
                }
            </FormGroup>
        </div>
        
    )
}

export default AnalysisListEntry;