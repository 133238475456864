import { _API_ } from "../../consts";
import _fetch from "../../_fetch";
import { Paint, Rarity } from "../items/item-slice";
import { AnalysisResult } from "./analysis-slice";

interface AnalysisParams {
    rlg_id: number;
    rli_id: number;
    paint: Paint;
}

export async function fetchAnalysis(params: AnalysisParams): Promise<AnalysisResult> {
    return await _fetch<AnalysisResult>('api/analysis', 'POST', params);
}