import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ItemState {
    items: Item[];
    status: 'idle' | 'loading' | 'failed';
}

export interface ItemId {
    rlg_id: number;
    rli_id: number;
}

export interface Item extends ItemId {
    category: string;
    name: string;
    rarity: Rarity;
    img_url: string;
    paints: PaintPrice[];
    hash: string;
}

export interface ConcreteItem extends Item {
    paint: Paint;
}

export enum Rarity {
    // Any = "A",
    Common = 0,
    Uncommon = 1,
    // Premium = 2 # Categories, not used
    Limited = 3,
    Rare = 4,
    // SuperRare = 5 # 0 items, not used
    VeryRare = 6,
    Import = 7,
    Exotic = 8,
    BlackMarket = 9,
    Legacy = 10,
}

export enum Paint {
    Default = 0,
    Black = 1,
    TitaniumWhite = 2,
    Grey = 3,
    Crimson = 4,
    Pink = 5,
    Cobalt = 6,
    SkyBlue = 7,
    BurntSienna = 8,
    Saffron = 9,
    Lime = 10,
    ForestGreen = 11,
    Orange = 12,
    Purple = 13,
    Gold = 14,
}

export interface PaintPrice {
    paint: Paint;
    price: [number, number] | null;
}

const initialState: ItemState = {
    items: [],
    status: 'idle',
};

export const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        receiveItems: (state, action: PayloadAction<Item[]>) => {
            state.items = action.payload;
        },
    },
});

export default itemSlice.reducer;
export const { receiveItems } = itemSlice.actions;