import * as React from "react";
import { FormGroup, Col } from "reactstrap";
import { AnalysisStatus } from "../../features/analysis/analysis-slice";
import AnalysisListEntry from "./AnalysisListEntry";

interface Props {
    list: AnalysisStatus[];
}

const AnalysisList = ({ list }: Props) => {
    return (<>
            <div className="selected-item-container">
                <div className="row">
                    <Col xs={4}>
                        <big>Item</big>
                    </Col>
                    <Col xs={4}>
                        <big>Who has</big>
                    </Col>
                    <Col xs={4}>
                        <big>Who wants</big>
                    </Col>
                </div>
            </div>    
            
            {list.map((analysis) => (
                <AnalysisListEntry
                    key={analysis.key}
                    status={analysis}
                />
            ))}
    </>)
}

export default AnalysisList;