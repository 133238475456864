import * as React from "react";
import { useMemo } from "react";
import { Platform } from "../../features/trade/trade-slice";

interface Props {
    platform: Platform;
    styles: React.CSSProperties;
}

const PlatformImg = ({ platform, styles }: Props) => {

    const src = useMemo(() => {
        switch (platform) {
            case Platform.Epic: return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Epic_Games_logo.svg/1764px-Epic_Games_logo.svg.png';
            case Platform.Steam: return 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/512px-Steam_icon_logo.svg.png';
            case Platform.PS: return 'https://static.rocket-league.com/assets/4e20fd204d4770271d668dfbf74ee26fc0fa8347/images/logos/psn_white.svg';
            case Platform.Xbox: return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Xbox_one_logo.svg/1024px-Xbox_one_logo.svg.png';
            case Platform.Nintendo: return 'https://static.rocket-league.com/assets/4e20fd204d4770271d668dfbf74ee26fc0fa8347/images/logos/switch_white.svg';
            case Platform.Discord: return 'https://www.freepnglogos.com/uploads/discord-logo-png/concours-discord-cartes-voeux-fortnite-france-6.png';
            default: {
                const typeguard: never = platform;
                throw new Error(`Unknown platform: ${typeguard}`)
            }
        }
    }, [platform]);
    
    return <img style={styles} src={src} alt="" />
}

export default PlatformImg;