import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FormGroup, Button } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchAnalysis } from "../../features/analysis/analysis-api";
import { AnalysisStatus } from "../../features/analysis/analysis-slice";
import { getPermissions } from "../../features/app/app-selectors";
import { ConcreteItem } from "../../features/items/item-slice";
import { tradeActions } from "../../features/trade/trade-logic";
import batchPromises from "../../utils/batchPromises";
import { useNotification } from "../../utils/useNotification";
import AnalysisList from "../common/AnalysisList";
import { SearchItem } from "./poll-certain-items-types";

interface Props {
    items: SearchItem[];
    onBack: () => void;
}

const ItemAnalysis = ({ items, onBack }: Props) => {

    const dispatch = useAppDispatch();
    const notify = useNotification();

    const batchSize = useAppSelector(getPermissions).max_concurrency;

    const concreteItems = useMemo<ConcreteItem[]>(() => {
        const result: ConcreteItem[] = [];

        for (const item of items) {
            for (const paint of item.paints) {
                result.push({ ...item.item, paint })
            }
        }

        return result;
    }, [items]);

    const [analysisResults, setAnalysisStatus] = useState<AnalysisStatus[]>(() => {
        return concreteItems.map(i => ({
            item: i,
            result: null,
            isLoading: false,
            key: `${i.hash}/${i.paint}`
        }));
    });

    const updateStatus = useCallback((index: number, part: Partial<AnalysisStatus>) => {
        setAnalysisStatus(prev => prev.map((entry, i) => i === index ? ({ ...entry, ...part }) : entry));
    }, []);

    useEffect(() => {
        let isMounted = true;

        const poll = async () => {
            const prev_results: Record<number, number | undefined> = {}
            let iteration = 0;

            while (isMounted) {

                const start = new Date();
                const promises: Promise<any>[] = [];

                let nextIndex = 0;

                const analyse = async (i: number) => {
                    try {
                        const concreteItem = concreteItems[i];
                        updateStatus(i, { isLoading: true });

                        const analysis = await fetchAnalysis({
                            rlg_id: concreteItem.rlg_id,
                            rli_id: concreteItem.rli_id,
                            paint: concreteItem.paint,
                        });

                        updateStatus(i, { result: analysis, isLoading: false, });

                        if (analysis.profit > 0 && analysis.profit > (prev_results[i] ?? 0)) {

                            toast(`Profit up to ${analysis.profit} was found`);

                            const tradesToLoadPlatforms = [
                                ...analysis.who_has.slice(0, 5),
                                ...analysis.who_wants.slice(0, 5),
                            ]

                            for (const trade of tradesToLoadPlatforms) {
                                await dispatch(tradeActions.lazyLoadUserPlatform(trade.trade.user_name))
                            }
                        }

                        prev_results[i] = analysis.profit;
                    }
                    catch (e: any) {
                        console.error(e.toString())
                    }
                }


                await batchPromises(batchSize, () => {
                    if (nextIndex === concreteItems.length)
                        return undefined;

                    return analyse(nextIndex++);
                });

                await Promise.all(promises);

                const end = new Date();
                console.log(`Iteration #${iteration}`, (end.valueOf() - start.valueOf()) / 1000);

                iteration++;
                await new Promise(r => setTimeout(r, 10000));
            }
        };

        poll();

        return () => {
            isMounted = false;
        }
    }, [dispatch, concreteItems, updateStatus, items, notify, batchSize]);

    return (
        <div>
            <FormGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button outline={true} size="sm" color="secondary" onClick={onBack}>
                    Back
                </Button>
            </FormGroup>
            <AnalysisList list={analysisResults}/>
        </div>
    )
}

export default ItemAnalysis;