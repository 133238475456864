import * as React from "react";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, FormGroup, Input, Form, Label, Row, Col, Collapse } from "reactstrap";
import { fetchAnalysis } from "../../features/analysis/analysis-api";
import { AnalysisResult, AnalysisStatus } from "../../features/analysis/analysis-slice";
import { useItems } from "../../features/items/item-logic";
import { Item, Paint, Rarity } from "../../features/items/item-slice";
import { fetchTradeFeed, fetchTradeFeedByUser } from "../../features/trade/trade-api";
import { TradeFeedEntry } from "../../features/trade/trade-slice";
import { createSorter } from "../../utils/createSorter";
import { useNotification } from "../../utils/useNotification";
import AnalysisList from "../common/AnalysisList";
import Moment from "react-moment";
import batchPromises from "../../utils/batchPromises";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getPermissions } from "../../features/app/app-selectors";
import { tradeActions } from "../../features/trade/trade-logic";

interface Props {

}

interface TradeFeedEntryExt extends TradeFeedEntry {
    item: Item;
    ratio: number;
}

type ConcreteItemKey = `${string}/${Paint}`

type WorkerFlag = 'idle' | 'started' | 'polling' | 'cancelled';

interface WorkerState {
    params: WorkerParams;
    flag: WorkerFlag;
    start: moment.Moment;
    iteration: number;
    itemsProcessed: number;
}

interface WorkerParams {
    min_credits: number;
    max_credits: number;
    min_profit: number;
    profit_agressiveness: number;
    profit_ttl: number;
    prev_best_retention: number;
    analysis_timeout: number;
    user_name: string;
    batch_size: number;
}

const sorter = createSorter<TradeFeedEntryExt>(e => e.ratio);

const PollTradeFeed = ({ }: Props) => {

    const dispatch = useAppDispatch();

    const [profits, setProfits] = useState<AnalysisStatus[]>([]);
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

    const permissions = useAppSelector(getPermissions);
    const batchSize = permissions.max_concurrency;

    const [workerState, setWorkerState] = useState<WorkerState>(() => ({
        params: {
            min_credits: 500,
            max_credits: 1800,
            min_profit: 100,
            profit_agressiveness: 2,
            profit_ttl: 15,
            prev_best_retention: 15,
            analysis_timeout: 60,
            user_name: '',
            batch_size: batchSize
        },
        flag: 'idle',
        start: moment(),
        iteration: 0,
        itemsProcessed: 0,
    }));

    const workerStateRef = useRef<WorkerState>(workerState);
    workerStateRef.current = workerState;

    useEffect(() => {
        if (workerState.params.user_name) {
            fetchTradeFeedByUser(workerState.params.user_name)
        }
    }, [workerState.params.user_name]);

    const updateStatePart = useCallback((part: Partial<WorkerState>) => setWorkerState(prev => ({ ...prev, ...part })), []);
    const updateParamsPart = useCallback((part: Partial<WorkerParams>) => setWorkerState(prev => ({ ...prev, params: { ...prev.params, ...part } })), []);

    const handleMinCredits = (v: number) => updateParamsPart({ min_credits: v });
    const handleMaxCredits = (v: number) => updateParamsPart({ max_credits: v });
    const handleMinProfit = (v: number) => updateParamsPart({ min_profit: v });
    const handleProfitAgressiveness = (v: number) => updateParamsPart({ profit_agressiveness: v });
    const handleProfitTtl = (v: number) => updateParamsPart({ profit_ttl: v });
    const handlePrevBestRetention = (v: number) => updateParamsPart({ prev_best_retention: v });
    const handleAnalysisTimeout = (v: number) => updateParamsPart({ analysis_timeout: v });
    const handleUserName = (v: string) => updateParamsPart({ user_name: v });
    const handleConcurrency = (v: number) => updateParamsPart({ batch_size: v });

    const [items, isLoading] = useItems();
    const notify = useNotification();

    const togglePolling = () => {
        if (workerState.flag === 'idle') {
            updateStatePart({ flag: 'started' })
        }
        else if (workerState.flag === 'polling') {
            updateStatePart({ flag: 'cancelled' })
        }
    }

    useEffect(() => {
        if (items.length === 0)
            return;

        const itemsDict: Record<string, Item> = {}

        for (const item of items) {
            itemsDict[`${item.rlg_id}/${item.rli_id}`] = item;
        }

        const prev_results: Record<ConcreteItemKey, { credits: number, timestamp: moment.Moment } | undefined> = {}
        const visited_profits = new Set<string>();

        const is_profit_visited = (analysis: AnalysisResult): boolean => {
            const keyEntries: any[] = [];
            keyEntries.push(...analysis.who_has.slice(0, analysis.top_profit_who_has).map(t => t.trade.trade_url))
            keyEntries.push(analysis.who_has[0].credits)
            keyEntries.push(analysis.who_wants[0].credits)

            const key = keyEntries.join(',')

            if (visited_profits.has(key))
                return true;

            visited_profits.add(key);
            return false;
        }

        let isMounted = true;

        const isFlag = (flag: WorkerFlag) => workerStateRef.current.flag === flag;

        const splitCredits = (min: number, max: number, batchSize: number): [number, number][] => {
            const result: [number, number][] = []
            const diff = max - min
            const unsafeDelta = diff / batchSize;
            
            // unsafe = 60 then delta = 60
            // unsafe = 63.8 then delta = 70
            const delta = unsafeDelta % 10 === 0 ? unsafeDelta : (Math.floor((unsafeDelta / 10) + 1) * 10)

            for (let i = 0; i < batchSize; i++) {
                const from = i === 0 ? min : (result[result.length - 1][1] + 10);
                const to = i === batchSize - 1 ? max : from + delta;
                result.push([from, to]);
            }

            return result
        }

        const poll = async () => {

            while (isMounted) {

                if (workerStateRef.current.flag === 'started') {
                    updateStatePart({ flag: 'polling', start: moment() });
                }

                while (isMounted &&  isFlag('started') || isFlag('polling')) {

                    setWorkerState(prev => ({ ...prev, iteration: prev.iteration + 1 }))

                    try {
                        const {
                            min_credits, max_credits, min_profit,
                            profit_agressiveness, profit_ttl, user_name,
                            prev_best_retention, analysis_timeout, batch_size
                        } = workerStateRef.current.params;

                        const min_date = moment().subtract(profit_ttl, 'minutes');
                        const feedParams = splitCredits(min_credits, max_credits, batch_size);

                        const entries: TradeFeedEntry[] = []

                        const fetchFeedSlice = async (min_credits: number, max_credits: number) => {
                            const feed = await fetchTradeFeed({
                                min_credits,
                                max_credits,
                                user_name,
                            });

                            entries.push(...feed);
                        }
                        
                        await batchPromises(batch_size, () => {
                            const nextParams = feedParams.shift();

                            return nextParams && fetchFeedSlice(nextParams[0], nextParams[1])
                        });

                        const analysisStart = moment();

                        // TODO deduplication
                        const entriesExt = entries.map<TradeFeedEntryExt>(e => {
                            const item = itemsDict[`${e.rlg_id}/${e.rli_id}`];
                            const prices = item.paints.find(p => p.paint === e.paint)!.price!;
                            const basePrice = prices[0]; // min price
                            return {
                                item,
                                ratio: e.credits / basePrice,
                                ...e,
                            }
                        }).sort(sorter);

                        const analyse = async (e: TradeFeedEntryExt) => {
                            const key: ConcreteItemKey = `${e.item.hash}/${e.paint}`;
                            const name: string = `${e.item.name} [${Paint[e.paint]} ${Rarity[e.item.rarity]}] #${workerStateRef.current.iteration}`;

                            const prev_result = prev_results[key];

                            if (prev_result &&
                                prev_result.credits <= e.credits &&
                                moment().diff(prev_result.timestamp, 'minutes', true) < prev_best_retention
                            ) {
                                console.log(`Recently visited and price was lower or equal (${prev_result.credits} <= ${e.credits}): ${name}`);
                                return;
                            }

                            prev_results[key] = { credits: e.credits, timestamp: moment() }

                            const analysis = await fetchAnalysis({
                                rlg_id: e.rlg_id,
                                rli_id: e.rli_id,
                                paint: e.paint,
                            });

                            setWorkerState(prev => ({ ...prev, itemsProcessed: prev.itemsProcessed + 1 }))

                            if (!analysis.profit) {
                                console.log(`No profit: ${name}`);
                                return;
                            }

                            const trade_from = analysis.who_has.slice(0, analysis.top_profit_who_has)
                            const trade_to = analysis.who_wants.slice(0, analysis.top_profit_who_wants)

                            if (trade_to.length < profit_agressiveness) {
                                console.log(`Not satisfied agressiveness (${trade_to.length}): ${name}`);
                                return;
                            }

                            const freshTrades = trade_from.filter(t => moment(t.trade.timestamp) > min_date);

                            if (freshTrades.length === 0) {
                                console.log(`Not fresh trades: ${name}`);
                                return;
                            }

                            const threshold_wants = trade_to[profit_agressiveness - 1].credits
                            const sexeh_trades = freshTrades.filter(t => threshold_wants - t.credits >= min_profit)

                            if (sexeh_trades.length === 0) {
                                console.log(`No sexeh trades: ${name}`);
                                return;
                            }

                            if (is_profit_visited(analysis)) {
                                console.log(`Profit for such trade set is already visited`);
                                return;
                            }

                            setProfits(prev => [
                                {
                                    item: {
                                        ...e.item,
                                        paint: e.paint
                                    },
                                    result: analysis,
                                    isLoading: false,
                                    key: analysis.timestamp,
                                },
                                ...prev
                            ]);

                            notify(`Profit up to ${analysis.profit} found`)
                            console.log('Found profit!', analysis, 'came via', e);

                            for (const trade of [...sexeh_trades.slice(0, 5), ...trade_to.slice(0, 5)]) {
                                await dispatch(tradeActions.lazyLoadUserPlatform(trade.trade.user_name));
                            }
                        }

                        await batchPromises(batch_size, () => {
                            if (isFlag('cancelled')) {
                                return undefined;
                            }
                            if (moment().diff(analysisStart, 'seconds', true) > analysis_timeout) {
                                return undefined;
                            }

                            const next = entriesExt.shift();

                            return next && analyse(next);
                        });
                    }
                    catch (e: any) {
                        console.log(e.toString())
                    }
                }

                if (!isFlag('idle')) {
                    updateStatePart({ flag: 'idle' });
                }
                await new Promise(r => setTimeout(r, 1000));
            }
        }

        poll();

        return () => {
            isMounted = false;
        }
    }, [dispatch, items, notify, updateStatePart, workerStateRef]);

    return (
        <div>
            <Form>
                <fieldset disabled={workerState.flag != 'idle'}>
                    <Row>
                        <Col>
                            <FormGroup floating>
                                <Input
                                    id="min"
                                    type="number"
                                    placeholder="Min credits"
                                    defaultValue={workerState.params.min_credits}
                                    onChange={e => handleMinCredits(e.target.valueAsNumber || 0)}
                                />
                                <Label for="min">Min credits</Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup floating>
                                <Input
                                    id="max"
                                    type="number"
                                    placeholder="Max credits"
                                    defaultValue={workerState.params.max_credits}
                                    onChange={e => handleMaxCredits(e.target.valueAsNumber || 0)}
                                />
                                <Label for="max">Max credits</Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup floating>
                                <Input
                                    id="profit"
                                    type="number"
                                    placeholder="Profit"
                                    defaultValue={workerState.params.min_profit}
                                    onChange={e => handleMinProfit(e.target.valueAsNumber || 0)}
                                />
                                <Label for="profit">Profit</Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup floating>
                                <Input
                                    id="agr"
                                    type="number"
                                    placeholder="Agressiveness"
                                    defaultValue={workerState.params.profit_agressiveness}
                                    onChange={e => handleProfitAgressiveness(e.target.valueAsNumber || 0)}
                                />
                                <Label for="agr">Agressiveness</Label>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup floating>
                                <Input
                                    id="ttl"
                                    type="number"
                                    placeholder="TTL"
                                    defaultValue={workerState.params.profit_ttl}
                                    onChange={e => handleProfitTtl(e.target.valueAsNumber || 0)}
                                />
                                <Label for="ttl">TTL</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {showAdvanced &&
                        <Collapse isOpen={showAdvanced}>
                            <Row>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id="user_name"
                                            type="text"
                                            placeholder="User name (optional)"
                                            value={workerState.params.user_name}
                                            onChange={e => handleUserName(e.target.value)}
                                        />
                                        <Label for="user_name">User name (optional)</Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id="prev-best-retention"
                                            type="number"
                                            placeholder="Previous best retention"
                                            defaultValue={workerState.params.prev_best_retention}
                                            onChange={e => handlePrevBestRetention(e.target.valueAsNumber || 0)}
                                        />
                                        <Label for="prev-best-retention">Previous best retention</Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id="analysis-timeout"
                                            type="number"
                                            placeholder="Analysis timeout"
                                            defaultValue={workerState.params.analysis_timeout}
                                            onChange={e => handleAnalysisTimeout(e.target.valueAsNumber || 0)}
                                        />
                                        <Label for="analysis-timeout">Analysis timeout</Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup floating>
                                        <Input
                                            id="concurrency"
                                            type="number"
                                            placeholder="Concurrency"
                                            defaultValue={workerState.params.batch_size}
                                            disabled={permissions.is_demo && !permissions.is_full_access}
                                            onChange={e => handleConcurrency(e.target.valueAsNumber || 0)}
                                        />
                                        <Label for="concurrency">Concurrency</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Collapse>
                    }
                </fieldset>
            </Form>

            <FormGroup style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Button size='sm' color="secondary" outline onClick={() => setShowAdvanced(p => !p)}>
                        {showAdvanced ? 'Hide' : 'Show'} advanced
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <big>
                        {workerState.flag === 'polling' && <Moment date={workerState.start} durationFromNow />}
                        &nbsp;
                        Items processed: {workerState.itemsProcessed},
                        &nbsp;
                        Iteration: #{workerState.iteration}
                    </big>
                    &nbsp;
                    <Button style={{marginLeft: 15}} size='sm' color="primary" disabled={workerState.flag === 'started' || workerState.flag === 'cancelled'} onClick={togglePolling}>
                        {(workerState.flag === 'idle' || workerState.flag === 'started') && 'Start'}
                        {(workerState.flag === 'polling' || workerState.flag === 'cancelled') && 'Stop'}
                    </Button>
                </div>
            </FormGroup>
            {profits.length > 0 && <AnalysisList list={profits} />}
        </div>
    )
}

export default PollTradeFeed;