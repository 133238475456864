import * as React from "react";
import Moment from "react-moment";
import { useAppSelector } from "../../app/hooks";
import { getPermissions } from "../../features/app/app-selectors";

const TokenExpiration = () => {

    const permissions = useAppSelector(getPermissions);
    
    if (!permissions.is_demo) {
        return <></>
    }
    
    return (
        <div>
            Demo ends <Moment date={permissions.token_expiration_ts} unit={'sec'} fromNow/>
        </div>
    )
}

export default TokenExpiration;