import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Input, Button } from "reactstrap";
import { useAppSelector } from '../../app/hooks';
import { getAppSettings } from '../../features/app/app-selectors';
import { AppSettings } from '../../features/app/app-slice';

const Settings = () => {

    const initValue = useAppSelector(getAppSettings);

    const [value, setValue] = useState<AppSettings>(initValue);

    const handlePart = (part: Partial<AppSettings>) => setValue(prev => ({ ...prev, ...part }));

    const handleApiServerThreads = (v: number) => handlePart({ api_server_threads: v })

    const handleSubmit = () => {
        toast.success('TODO saving');
    }

    return (
        <div>
            <div style={{ display: "flex" }}>
                <Input
                    type="number"
                    value={value.api_server_threads}
                    onChange={e => handleApiServerThreads(e.target.valueAsNumber)}
                />
                <Button onClick={handleSubmit} style={{ marginLeft: 15 }}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default Settings;