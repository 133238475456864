import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";

const Anchor = () => {

    const [scroll, setScroll] = useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const handleUpButton = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div>
            {scroll > document.documentElement.clientHeight && 
                <div className='anchor' onClick={handleUpButton}>
                    <FontAwesomeIcon style={{width: "80px", height: "80px"}} icon={faCircleChevronUp} />
                </div>
            }
        </div>
    );
};

export default Anchor;