
type Selector<T> = (obj: T) => string | number | boolean;
type Sorter<T> = (obj1: T, obj2: T) => number;
type SortOrder = 'asc' | 'desc';

export function createSorter<T>(selector: Selector<T>, order: SortOrder = 'asc'): Sorter<T> {
    return (obj1, obj2) => {
        const s1 = selector(obj1);
        const s2 = selector(obj2);
        const result = (
            s1 > s2 ? 1 :
            s1 < s2 ? -1 :
            0
        );
        return order === 'desc' ? (result * -1) : result;
    }    
}