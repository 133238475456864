import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import itemReducer from '../features/items/item-slice';
import appReducer from '../features/app/app-slice';
import tradeReducer from '../features/trade/trade-slice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    app: appReducer,
    item: itemReducer,
    trade: tradeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
