import { AppThunk } from "../../app/store";
import { fetchTradeFeedByUser } from "./trade-api";
import { isUserPlatformRequested } from "./trade-selectors";
import { receiveUserPlatform, UserTradeFeed } from "./trade-slice";

export const tradeActions = {
    loadUserProfile: (user: string): AppThunk<Promise<UserTradeFeed>> => async (dispatch, getState) => {
        dispatch(receiveUserPlatform([user, true, []]));

        const result = await fetchTradeFeedByUser(user);
        dispatch(receiveUserPlatform([user, false, result.platforms]));

        return result;
    },
    lazyLoadUserPlatform: (user: string): AppThunk => async (dispatch, getState) => {
        if (isUserPlatformRequested(getState(), user))
            return;

        await dispatch(tradeActions.loadUserProfile(user));
    },
}