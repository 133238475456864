import * as React from "react";
import { Paint } from "../../features/items/item-slice";

interface Props {
    paint: Paint;
}

export const paintClass: Record<Paint, string> = {
    [Paint.Default]: '--default',
    [Paint.Black]: '--black',
    [Paint.TitaniumWhite]: '--titanium-white',
    [Paint.Grey]: '--grey',
    [Paint.Crimson]: '--crimson',
    [Paint.Pink]: '--pink',
    [Paint.Cobalt]: '--cobalt',
    [Paint.SkyBlue]: '--sky-blue',
    [Paint.BurntSienna]: '--burnt-sienna',
    [Paint.Saffron]: '--saffron',
    [Paint.Lime]: '--lime',
    [Paint.ForestGreen]: '--forest-green',
    [Paint.Orange]: '--orange',
    [Paint.Purple]: '--purple',
    [Paint.Gold]: '--gold',
}

const PaintBadge = ({ paint }: Props) => {
    return (
        <div className={`rlg-item__paint ${paintClass[paint]} --inline`} style={{ height: '1.5em'}}>
            {Paint[paint]}
        </div>
    )
}

export default PaintBadge;