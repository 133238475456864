import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppThunk } from "../../app/store";
import { fetchItems } from "./item-api";
import { getItems } from "./item-selector";
import { Item, receiveItems } from "./item-slice";

export const itemActions = {
    loadItems: (): AppThunk => async (dispatch, getState) => {
        if (getItems(getState()).length > 0)
            return;

        const items = await fetchItems();
        dispatch(receiveItems(items));
    }
}

type UseItemsResult = [
    Item[],
    boolean
]

export const useItems = (): UseItemsResult => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(itemActions.loadItems())
    }, [dispatch]);

    const items = useAppSelector(getItems);

    return [items, false]; // TODO isLoading
}