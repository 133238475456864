const batchPromises = async (batchSize: number, factory: () => (Promise<void> | undefined)): Promise<void> => {

    const promise_dict: Record<number, Promise<number>> = {}
    let currentIndex: number = 0;

    const wrapPromise = async (promise: Promise<void>, index: number) => {
        try {
            await promise;
        }
        catch (e) {

        }
        finally {
            return index;
        }
    }

    while (1) {
        while (Object.keys(promise_dict).length < batchSize) {
            const next = factory();

            if (!next) {
                break;
            }

            const idx = currentIndex++;
            promise_dict[idx] = wrapPromise(next, idx)
        }

        if (Object.keys(promise_dict).length === 0) {
            break;
        }

        const resolvedIndex = await Promise.race(Object.values(promise_dict));

        delete promise_dict[resolvedIndex];
    }
}

export default batchPromises;