import { useMemo } from "react";
import toast, { ToastOptions } from "react-hot-toast";
import Moment from "react-moment";
import { CloseButton, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";
import { useAppSelector } from "../../app/hooks";
import { Trade, TradeType } from "../../features/analysis/analysis-slice";
import { blashlistUser } from "../../features/blacklist/blacklist-api";
import { ConcreteItem, Paint, Rarity } from "../../features/items/item-slice";
import { makeGetUserPlatform } from "../../features/trade/trade-selectors";
import Circle from "../common/Circle";
import Nice from "../common/Nice";
import PlatformImg from "../common/PlatformImg";

interface TradeOfferProps {
    trade: Trade;
    type: TradeType;
    credits: number;
    item: ConcreteItem;
    isProfit: boolean;
}

const toastOptions: ToastOptions = { // Unify some way
    duration: 1000000000,
    style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
    }
}

const TradeOffer = ({ trade, type, credits, item, isProfit }: TradeOfferProps) => {

    const getUserPlatform = useMemo(makeGetUserPlatform, []);

    const { isLoading, platforms } = useAppSelector(state => getUserPlatform(state, trade.user_name));

    const item_str = `${item.name} [${Paint[item.paint]}, ${Rarity[item.rarity]}]`;
    const credits_str = `${credits} credits`;

    const handlePin = () => {
        toast(t => (
            <span>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <a href={trade.trade_url} target='_blank'>
                        {trade.user_name}
                    </a>
                    <CloseButton variant="white" onClick={() => toast.dismiss(t.id)}/>
                </div>
                [H] {type === TradeType.Has ? item_str : credits_str}
                <br/>
                [W] {type === TradeType.Has ? credits_str : item_str}
            </span>
        ), toastOptions)
    }

    const handleBlacklist = async () => {
        await toast.promise(
            blashlistUser(trade.user_name),
            {
                loading: 'Blacklisting...',
                success: `Successfully blacklisted ${trade.user_name}`,
                error: e => `Could not blacklist: ${e.message}`,
            }
        )
    }

    return (
        <div>
            <a href={trade.trade_url} target='_blank'>
                {trade.user_name}
            </a>
            &nbsp;
            {(isLoading || !!platforms.length) && <>
                {isLoading && <Spinner size='sm' />}
                {!!platforms.length && platforms.map(p => (
                    <PlatformImg
                        key={p}
                        platform={p}
                        styles={{ height: '18px', marginLeft: '3px' }}
                    />
                ))}
                &nbsp;
            </>}
            {trade.online && <Circle styles={{ marginRight: 5 }}/>}
            &nbsp;
            <b>
                {isProfit ? <Nice>{credits}</Nice> : credits}
            </b>
            &nbsp;
            <small>
                [<Moment date={trade.timestamp} durationFromNow/>]
            </small>
            &nbsp;
            <UncontrolledDropdown direction='end' style={{ display: 'inline-block', cursor: 'pointer', padding: '0px 5px 0px 5px' }}>
                <DropdownToggle tag="span" >
                    ⠇
                </DropdownToggle>
                <DropdownMenu dark>
                    <DropdownItem onClick={handlePin}>Pin</DropdownItem>
                    <DropdownItem onClick={handleBlacklist}>Blacklist</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            {trade.note && <>
                <br/>
                <small>
                    <small>
                        {trade.note}
                    </small>
                </small>
            </>
            }
        </div>
    )
}

export default TradeOffer;