import React, {useState} from 'react';
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Link } from "react-router-dom";
import { Button, CloseButton, FormGroup } from "reactstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from '../../app/hooks';
import { getPermissions } from '../../features/app/app-selectors';

interface Open {
    isPaneOpen: boolean,
    isPaneOpenLeft: boolean
}

const Sidebar = () => {

    const [open, setOpen] = useState<Open>({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });

    const permissions = useAppSelector(getPermissions);

    return (
        <div style={{position: "relative"}}>
            <div style={{ marginTop: "32px" }}>
                <FontAwesomeIcon style={{cursor: "pointer", width: "30px", height: "30px"}} icon={faBars} onClick={() => setOpen({...open, isPaneOpenLeft: true})} />
            </div>
            <SlidingPane
                className='side-bar'
                hideHeader
                isOpen={open.isPaneOpenLeft}
                title="Hey, it is optional pane title.  I can be React component too."
                from="left"
                width="340px"
                onRequestClose={() => setOpen({...open, isPaneOpenLeft: false})}
            >
                <div style={{paddingTop: 45}}>
                    <CloseButton style={{position: "absolute", top: 13, right: 13}} variant="white" onClick={() => setOpen({...open, isPaneOpenLeft: false})}/>
                    <FormGroup>
                        <Link to='/poll-certain'>
                            <Button block color="primary" size="sm"> 
                                Poll certain
                            </Button>
                        </Link>
                    </FormGroup>
                    <FormGroup>
                        <Link to='/poll-feed'>
                            <Button block color="primary" size="sm"> 
                                Poll feed
                            </Button>
                        </Link>
                    </FormGroup>
                    {!permissions.is_demo &&
                        <FormGroup>
                                <Link to='/settings'>
                                    <Button block color="secondary" size="sm">
                                        Settings
                                    </Button>
                                </Link>
                        </FormGroup>
                    }
                </div>
            </SlidingPane>
        </div>
    );
};
export default Sidebar;