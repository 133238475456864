import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const getSlice = (state: RootState) => state.app;

type Permission = {
    is_demo: false;
    max_concurrency: number;
} | {
    is_demo: true;
    token_expiration_ts: number;
    is_full_access: boolean;
    max_concurrency: number;
}

export const getAppSettings = createSelector(
    [getSlice],
    slice => slice.settings
)

export const getAppState = createSelector(
    [getSlice],
    slice => slice.state
)

export const getApiServerThreads = createSelector(
    [getAppSettings],
    state => state.api_server_threads
)

export const getPermissions = createSelector(
    [getAppSettings],
    (state): Permission => {
        const is_demo = !!state.token_expiration_timestamp;

        if (is_demo) {
            return {
                is_demo: true,
                token_expiration_ts: state.token_expiration_timestamp,
                is_full_access: state.is_admin,
                max_concurrency: state.is_admin ? state.api_server_threads : 1,
            }
        }
        else {
            return {
                is_demo: false,
                max_concurrency: state.api_server_threads,
            }
        }
    }
)