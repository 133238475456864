import { ConcreteItem } from "../items/item-slice";

export interface AnalysisResult {
    profit: number;
    top_profit_who_has: number;
    top_profit_who_wants: number;
    who_has: TradeCredits[];
    who_wants: TradeCredits[];
    timestamp: string;
}

export interface AnalysisStatus {
    item: ConcreteItem;
    result: AnalysisResult | null;
    isLoading: boolean;
    key: string;
}

export interface Trade {
    user_url: string;
    trade_url: string;
    user_name: string;
    online: boolean;
    timestamp: string;
    note: string;
}

export enum TradeType {
    Has = 'h',
    Wants = 'w',
}

export interface TradeCredits {
    credits: number;
    trade: Trade;
}