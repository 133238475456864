import { useState } from "react";

import ItemAnalysis from "./ItemAnalysis";
import ItemSelection from "./ItemSelection";
import { SearchItem } from "./poll-certain-items-types";

interface Props {
    
}

const PollCertainItems = ({}: Props) => {
    const [selectedItems, setSelectedItems] = useState<SearchItem[]>([]);
    const [isSelecting, setIsSelecting] = useState<boolean>(true);

    return (<>
        {isSelecting ?
            <ItemSelection
                selectedItems={selectedItems}
                onChange={setSelectedItems}
                onSubmit={() => setIsSelecting(false)}
            /> :
            <ItemAnalysis 
                items={selectedItems}
                onBack={() => setIsSelecting(true)}    
            />
        }
    </>)
}

export default PollCertainItems;