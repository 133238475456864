import { _API_ } from "./consts";

const _fetch = async <T>(uri: string, method: RequestInit['method'], json?: any): Promise<T> => {

    const token = localStorage.getItem('demo_token')

    const authHeaders = token && {
        'Authorization': token
    }

    const response = await fetch(`${_API_}/${uri}`, {
        method: method,
        body: json && JSON.stringify(json),
        headers: {
            'Content-Type': 'application/json',
            ...authHeaders,
        }
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(error)
    }
    
    return await response.json();

}

export default _fetch;