import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Item, ItemId } from "./item-slice";

const getSlice = (state: RootState) => state.item;

const getItemId = (state: RootState, itemId: ItemId) => itemId;

type ItemId_str = `${number}/${number}`;

export const getItems = createSelector(
    [getSlice],
    slice => slice.items
)

export const getItemsDict = createSelector(
    [getItems],
    items => {
        const dict: Record<ItemId_str, Item> = {};

        for (const item of items) {
            dict[`${item.rlg_id}/${item.rli_id}`] = item;
        }

        return dict;
    }
)


export const getItem = createSelector(
    [getItemsDict, getItemId],
    (dict, itemId) => dict[`${itemId.rlg_id}/${itemId.rli_id}`]
)