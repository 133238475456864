import React, { useMemo, useState } from "react";
import { Badge, Button, FormGroup, Input, CloseButton } from "reactstrap";
import { useItems } from "../../features/items/item-logic";
import { Item, Paint, Rarity } from "../../features/items/item-slice";
import useDebounce from "../../utils/useDebounce";
import ItemContainer from "./ItemContainer";
import { SearchItem } from "./poll-certain-items-types";
import { paintClass } from "../common/PaintBadge";

interface Props {
    selectedItems: SearchItem[];
    onChange: (i: SearchItem[]) => void;
    onSubmit: () => void;
}

const ItemSelection = ({ selectedItems, onChange, onSubmit }: Props) => {

    const [input, setInput] = useState<string>('');
    const debouncedInput = useDebounce(input, 500);

    const [items] = useItems();

    const filteredItems = useMemo<Item[]>(() => {
        return items.filter(i => (
            i.name.toLowerCase().includes(debouncedInput.toLowerCase()) &&
            selectedItems.every(ii => ii.item.hash !== i.hash)
        ));
    }, [items, selectedItems, debouncedInput]);

    const handleAdd = (item: Item) => {
        onChange([
            ...selectedItems,
            {
                item,
                paints: [],
            }
        ]);
    }

    const handleRemove = (item: Item) => {
        onChange(selectedItems.filter(i => i.item.hash !== item.hash))
    }

    const togglePaint = (itemIndex: number, paint: Paint) => {
        onChange(selectedItems.map((si, i) => {
            if (i !== itemIndex) return si;

            return {
                ...si,
                paints: si.paints.includes(paint)
                    ? si.paints.filter(p => p !== paint)
                    : [...si.paints, paint]
            }
        }))
    }
    
    const toggleAllPaints = (itemIndex: number) => {
        onChange(selectedItems.map((si, i) => {
            if (i !== itemIndex) return si;
            
            return {
                ...si,
                paints: si.item.paints.map((p) => p.paint)
            }
        }))
    }

    const removeAllPaints = (itemIndex: number) => {
        onChange(selectedItems.map((si, i) => {
            if (i !== itemIndex) return si;

            return {
                ...si,
                paints: []
            }
        }))
    }

    return (<>
        <FormGroup>
            <Input type="text" value={input} onChange={e => setInput(e.target.value)} />
        </FormGroup>
        <FormGroup>
            {selectedItems.map((si, i) => (
                <div className='selected-item-container' key={si.item.hash}>
                    <div style={{display: "flex",}}>
                        <span style={{display: "block", marginBottom: "10px"}}>
                            {si.item.name} [{Rarity[si.item.rarity]}, {si.item.category}]
                        </span>
                            <CloseButton 
                                variant="white"
                                style={{position: "absolute", top: "10px", right: "9px", width: "26px", height: "26px", cursor: "pointer"}}
                                onClick={() => handleRemove(si.item)}
                            />
                    </div>
                    <div style={{display: "flex"}}>
                        {si.item.paints.map(({ paint, price }) => (
                            <Badge
                                key={paint} 
                                onClick={() => togglePaint(i, paint)}
                                className={paintClass[paint]}
                                style={{
                                    marginLeft: '5px',
                                    cursor: 'pointer',
                                    opacity: si.paints.includes(paint) ? 1 : 0.3,
                                    
                                }}
                            >
                                {Paint[paint]}
                                {price &&
                                    <small>
                                        <br />
                                        {price[0]}-{price[1]}
                                    </small>
                                }
                            </Badge> 
                        ))}
                        <div style={{marginLeft: "15px"}}>
                            <a style={{cursor: "Pointer", marginRight: "20px"}} onClick={() => removeAllPaints(i)}>
                                clear
                            </a>
                            <a style={{cursor: "Pointer"}} onClick={() => toggleAllPaints(i)}>
                                use all
                            </a>
                        </div>
                    </div>
                </div>
            ))}
            {!!selectedItems.length &&
                <Button style={{marginTop: "10px"}} color="primary" onClick={onSubmit}>
                    Check prices
                </Button>
            }
        </FormGroup>
        <FormGroup>
            <div className="item-container">
                {filteredItems.map(i => (
                    <ItemContainer
                        key={i.hash}
                        item={i}
                        onClick={() => handleAdd(i)}
                    />
                ))}
            </div>
        </FormGroup>
    </>)
}

export default ItemSelection;