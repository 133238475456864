import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Layout from './components/Layout/Layout';
import PollCertain from './components/PollCertainItems/PollCertainItems';
import PollTradeFeed from './components/PoolTradeFeed/PollTradeFeed';
import Settings from './components/Settings/Settings';

function App() {
    return (
        <Layout>
            <Routes>
                <Route path="/poll-certain" element={<PollCertain />} />
                <Route path="/poll-feed" element={<PollTradeFeed />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/" element={<Navigate to="poll-certain" />} />
            </Routes>
        </Layout>
  )
}

export default App;
